import styled from '@emotion/styled-base';

import Heading from 'components/Heading';
import Container from 'components/Container';

export const Section = styled(Container)`
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  margin-bottom: 20px;
`;

export const SectionHeading = styled(Heading)`
  width: 100%;
  font-size: 18px;
  padding: 16px;
  background-color: rgb(255, 255, 255);
  border-radius: 5px 5px 0 0;
  color: #333;
`;

export const SectionContent = styled(Container)`
  padding-top: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  border-radius: 0 0 5px 5px;
`;

export const SectionGrid = styled(SectionContent)`
  display: grid;
  grid-gap: 18px 12px;
  grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
`;

export default Section;

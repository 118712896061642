import React from 'react';
import Img from 'gatsby-image';
import { StaticQuery, graphql } from 'gatsby';
import { Box } from '@rebass/grid/emotion';

const Image = props => (
  <Box width={1}>
    <Img {...props} />
  </Box>
);

export default props => {
  const { alt = '', childImageSharp, image, filename } = props;

  if (props.cms === true) {
    return <img src={filename} alt={alt} style={{ maxWidth: '100%' }} />
  }

  if (!!image && !!image.childImageSharp) {
    return <Image fluid={image.childImageSharp.fluid} alt={alt} />
  }

  if (!!childImageSharp) {
    return <Image fluid={childImageSharp.fluid} alt={alt} />
  }

  if (filename) {
    return (
      <StaticQuery
        query={query}
        render={data => {
          const { edges } = data.images;
          const foundImage = edges.find(({ node: { path }}) => path.includes(filename));
          if (!foundImage) return null;

          const { extension, name, childImageSharp } = foundImage.node;

          if (extension === 'svg') {
            return <Image name={name} />;
          }

          if (childImageSharp) {
            return <Image sharp={childImageSharp} alt={alt} />
          }
        }}
      />
    );
  }

  if (!!image && typeof image === 'string') {
    return <img src={image} alt={alt} style={{ maxWidth: '100%' }} />
  }

  return null
}

const query = graphql`
  query {
    images: allFile {
      edges {
        node {
          relativePath
          path: absolutePath
          name
          base
          extension
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
